import { notification } from 'antd';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { v4 } from 'uuid';
import {
  CLONE_SITEMAP,
  DELETE_MENU_ITEM,
  DELETE_SITEMAP_REQUEST,
  EDIT_SITEMAP,
  GET_ALL_COURSES,
  GET_ALL_MENU_ITEMS,
  GET_LANGUAGES,
  GET_MENU_ITEM,
  GET_REGIONS_REQUEST,
  GET_ROOT_ITEM,
  GET_SITEMAP_REQUEST,
  GET_SITEMAPS_REQUEST,
  ON_REORDER_LIST,
  SAVE_AND_CONTINUE,
  SAVE_GROUP_ITEM,
  SAVE_LINK_ITEM_REQUEST,
  TOGGLE_SITEMAP_REQUEST,
  UPDATE_ITEM_REQUEST,
} from '../../constants/ActionTypes';
import { getSortedMenuItemsWithChanges } from '../../routes/NewSitemapMenu/utils';
import { rolesMapper } from '../../util/rolesMapper';
import history from '../history';
import { fetchError } from '../mainActions';
import {
  cloneSitemapError,
  cloneSitemapSuccess,
  deleteMenuItemError,
  deleteMenuItemSuccess,
  deleteSitemapError,
  deleteSitemapSuccess,
  getAllCoursesError,
  getAllCoursesSuccess,
  getAllMenuItems,
  getAllMenuItemsError,
  getAllMenuItemsSuccess,
  getLanguagesError,
  getLanguagesSuccess,
  getMenuItemError,
  getMenuItemSuccess,
  getRegionsSuccess,
  getRootItemError,
  getRootItemSuccess,
  getSitemapError,
  getSitemapsError,
  getSitemapsSucess,
  getSitemapSuccess,
  onReorderList,
  onReorderListError,
  saveAndContinueError,
  saveAndContinueSuccess,
  saveGroupItemError,
  saveGroupItemSuccess,
  saveLinkItemError,
  saveLinkItemSuccess,
  toggleSitemapError,
  toggleSitemapSuccess,
  updateItemError,
} from './actions';
import * as sitemapsApi from './dataSource';

const getId = (state) => state.sitemaps?.menuItem?.id;
const getMenuItems = (state) => state.sitemaps?.menuItems;
const getRootItem = (state) => state.sitemaps?.rootItem;
const getLangs = (state) => state.sitemaps?.languages;

function* runGetRegions() {
  try {
    const regions = yield sitemapsApi.getRegions();

    yield put(getRegionsSuccess(regions));
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runSaveAndContinue({ payload }) {
  try {
    const saveBody = {
      id: v4(),
      payload,
    };
    yield call(sitemapsApi.saveSitemap, saveBody);
    yield put(
      saveAndContinueSuccess({
        id: saveBody.id,
        ...saveBody.payload,
      })
    );
  } catch (e) {
    if (e.response.status !== 401 && e.response.status !== 403) {
      notification.error({ message: e.response.data.detail });
    }
    yield put(saveAndContinueError());
  }
}

function* runEditSitemap({ payload }) {
  const { id } = payload;

  try {
    yield call(sitemapsApi.editSitemap, { id, payload: payload.body });
    const { data } = yield call(sitemapsApi.getSitemap, id);
    yield put(
      getSitemapSuccess({
        ...data.attributes,
        id: data.id,
      })
    );
  } catch (e) {
    if (e.response.status !== 401 && e.response.status !== 403) {
      notification.error({ message: e.response.data.detail });
    }
    yield put(saveAndContinueError());
  }
}

function* runGetSitemaps({ payload }) {
  try {
    const { data } = yield call(sitemapsApi.getSitemaps, payload);

    if (data) {
      const sitemaps = data.data.map((sitemap) => ({
        ...sitemap.attributes,
        id: sitemap.id,
      }));
      yield put(getSitemapsSucess(sitemaps));
    }
  } catch (e) {
    yield put(getSitemapsError());
  }
}

function* runGetSitemap({ payload }) {
  try {
    const { data } = yield call(sitemapsApi.getSitemap, payload);
    if (data) {
      yield put(
        getSitemapSuccess({
          ...data.attributes,
          id: data.id,
        })
      );
    }
  } catch (e) {
    yield put(getSitemapError());
  }
}

function* runToggleSitemap({ payload }) {
  try {
    if (payload.checked) {
      yield call(sitemapsApi.activateSitemap, payload.id);
    } else {
      yield call(sitemapsApi.deactivateSitemap, payload.id);
    }

    yield put(toggleSitemapSuccess(payload));
  } catch (e) {
    yield put(toggleSitemapError());
  }
}

function* runDeleteSitemap({ payload }) {
  try {
    yield call(sitemapsApi.deleteSitemap, payload);
    yield put(deleteSitemapSuccess(payload));
  } catch (e) {
    yield put(deleteSitemapError());
    notification.error({ message: e.response.data.detail });
  }
}

function* runGetAllLanguages() {
  try {
    const languages = yield call(sitemapsApi.getLanguages);
    yield put(getLanguagesSuccess(languages));
  } catch (error) {
    yield put(getLanguagesError(error));
  }
}

function* getNewOrder(data) {
  const { body, id } = data;
  const menuItem = { ...body, id };
  const _menuItems = yield select(getMenuItems);
  const rootItem = yield select(getRootItem);

  const isParentRootElement = rootItem.rootMenuItemId === null;
  const groupItems = _menuItems.find(
    (item) => item.id === menuItem.rootMenuItemId
  ).items;

  if (groupItems.length === 0) return { currentItemOrder: 0, changes: [] };

  let newData = [];
  // create new menu items array with the new menuItem
  if (isParentRootElement) {
    newData = [...groupItems, menuItem];
  } else {
    newData = groupItems.map((item) =>
      item.id === menuItem.parentMenuItemId
        ? { ...item, items: [...item.items, menuItem] }
        : item
    );
  }
  // sort all de items with the added item and get the new menuItemOrder and the changes to perform bbdd
  const { menuItems, changes } = getSortedMenuItemsWithChanges(newData);
  let currentItemOrder;

  if (isParentRootElement) {
    currentItemOrder = menuItems.find((item) => item.id === id).menuItemOrder;
  } else {
    currentItemOrder = menuItems
      .find((item) => item.id === menuItem.parentMenuItemId)
      .items.find((item) => item.id === id).menuItemOrder;
  }

  // remove from changes de added item
  return {
    currentItemOrder,
    changes: changes.filter((change) => change.id !== id),
  };
}

function* runSaveGroupItem({ payload }) {
  const id = yield select(getId);
  try {
    const body = {
      ...payload,
      roles: rolesMapper(payload.roles),
    };
    const data = {
      body,
      id: id || v4(),
    };
    const { currentItemOrder, changes } = yield call(getNewOrder, data);
    data.body.menuItemOrder = currentItemOrder;
    yield put(onReorderList({ changes }));
    yield call(sitemapsApi.saveGroupItem, data);
    yield put(
      saveGroupItemSuccess({
        ...data.body,
        id: data.id,
      })
    );
    history.push(`/newSitemap/${data.body.sitemapId}`);
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(saveGroupItemError(error));
  }
}

function* runUpdateGroupItem({ payload }) {
  try {
    const data = {
      body: payload,
      id: payload.id,
    };

    if (payload.menuType === 'group') {
      yield call(sitemapsApi.saveGroupItem, data);
    } else {
      yield call(sitemapsApi.saveLinkItem, data);
    }

    const { region, courseOrder, level } = yield select(
      (state) => state.sitemaps.sitemap
    );

    yield put(
      getAllMenuItems({ region, courseOrder, role: 'ROLE_TEACHER', level })
    );
  } catch (error) {
    yield put(updateItemError(error));
  }
}

function* runSaveLinkItem({ payload }) {
  const id = yield select(getId);
  const body = {
    ...payload,
    roles: rolesMapper(payload.roles),
  };
  try {
    const data = {
      body,
      id: id || v4(),
    };
    const { currentItemOrder, changes } = yield call(getNewOrder, data);
    data.body.menuItemOrder = currentItemOrder;
    yield put(onReorderList({ changes }));
    yield call(sitemapsApi.saveLinkItem, data);
    yield put(
      saveLinkItemSuccess({
        ...data.body,
        id: data.id,
      })
    );
    history.push(`/newSitemap/${data.body.sitemapId}`);
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(saveLinkItemError(error));
  }
}

function* runReorderList({ payload }) {
  try {
    const { changes } = payload;
    if (changes.length) {
      yield call(sitemapsApi.changeMultipleOrders, changes);
    }
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(onReorderListError(error));
  }
}

function findChilds(element, list) {
  return {
    ...element,
    items: list
      .filter((item) => item.parentMenuItemId === element.id)
      .sort((a, b) => a.menuItemOrder - b.menuItemOrder)
      .map((child) => findChilds(child, list)),
  };
}

function* runGetAllMenuItems({ payload }) {
  try {
    const { data } = yield call(sitemapsApi.getAllMenuItems, payload);
    const itemsFlat = data.data.map((item) => ({
      ...item.attributes,
      id: item.id,
    }));
    const menuItems = itemsFlat
      .map((g) => findChilds(g, itemsFlat))
      .sort((a, b) => a.menuItemOrder - b.menuItemOrder)
      .filter((item) => item.parentMenuItemId === null);

    yield put(getAllMenuItemsSuccess(menuItems));
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(getAllMenuItemsError(error));
  }
}

function* runGetRootMenuItem({ payload }) {
  try {
    const { data } = yield call(sitemapsApi.getMenuItem, payload);
    const item = {
      ...data.attributes,
      id: data.id,
    };
    yield put(getRootItemSuccess(item));
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(getRootItemError(error));
  }
}

function* runGetMenuItem({ payload }) {
  try {
    const { data } = yield call(sitemapsApi.getMenuItem, payload);
    const item = {
      ...data.attributes,
      id: data.id,
    };
    yield put(getMenuItemSuccess(item));
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(getMenuItemError(error));
  }
}

function* runDeleteMenuItem({ payload }) {
  try {
    yield call(sitemapsApi.deleteMenuItem, payload.id);
    yield put(deleteMenuItemSuccess(payload));
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(deleteMenuItemError(error));
  }
}

function* runGetAllCourses() {
  try {
    const courses = yield call(sitemapsApi.getAllCourses);

    yield put(getAllCoursesSuccess(courses));
  } catch (error) {
    notification.error({ message: error.response.data.detail });
    yield put(getAllCoursesError(error));
  }
}

function* runCloneSitemap({ payload }) {
  try {
    const { from, to } = payload;
    const { data } = yield call(sitemapsApi.getAllMenuItems, from);
    const langs = yield select(getLangs);

    // CREATE SITEMAP
    const newSitemap = {
      id: v4(),
      payload: to,
    };

    yield call(sitemapsApi.saveSitemap, newSitemap);

    // GET DEFAULT MENU ITEMS CREATED IN BACKEND
    const { data: rootMenuItems } = yield call(sitemapsApi.getAllMenuItems, to);

    const currentRootItems = rootMenuItems.data.map((item) => ({
      id: item.id,
      body: { ...item.attributes },
    }));

    // GET ROOT ELEMENTS "AULA, FORMACION, PRACTICA" TO CHANGE THE IDS TO THE NEW SITEMAP
    // CREATE OBJECT WITH THE EQUIVALENTE ID'S
    const rootIdsMap = {};
    data.data
      .map((item) => ({
        id: item.id,
        ...item.attributes,
      }))
      .filter(
        (item) => item.parentMenuItemId === null && item.rootMenuItemId === null
      )
      .forEach((item) => {
        const from = item.id;
        const to = currentRootItems.find(
          (root) => root.body.urlName === item.urlName
        )?.id;
        rootIdsMap[from] = to;
      });

    const menuItems = data.data
      .filter((item) => !!item.attributes.rootMenuItemId)
      ?.map((item) => {
        const currentTranslations = item.attributes.translations;
        const translations = langs[to.region]?.map((language) => {
          // GET THE EQUIVALENT TRANSLATION OR THE FIRST BY DEFAULT
          const lang =
            currentTranslations.find((t) => t.languageId === language) ||
            currentTranslations[0];
          return {
            languageId: language,
            status: lang.status,
            value: lang.value || lang.value,
            label: 'name',
          };
        });

        // CREAR RELACION ENTRE EL ANTIGUO ID Y EL NUEVO ID PARA MAPEAR LOS HIJOS DESPUES
        const newId = v4();
        rootIdsMap[item.id] = newId;

        return {
          id: newId,
          body: {
            ...item.attributes,
            translations,
            sitemapId: newSitemap.id,
            isOnlyForClassroom: newSitemap.isOnlyForClassroom,
            rootMenuItemId: rootIdsMap[item.attributes.rootMenuItemId] || null,
          },
        };
      })
      .map((item) => {
        // TODO: RECORRER TODO EL ARRAY OTRA VEZ PARA MODIFICAR LOS PARENT IDS CON LOS NUEVOS IDS GENERADOS
        // BUSCAR EN rootIdsMap EL PARENT ID I DEVOLVER EL NUEVO ID
        const getParentId = (id) => {
          if (id === null) return null;
          return rootIdsMap[id] || id;
        };

        return {
          id: item.id,
          body: {
            ...item.body,
            parentMenuItemId: getParentId(item.body.parentMenuItemId),
          },
        };
      });

    // CONCAT THE FIRST ROOTMENU WITH THE NEW MENU ITEMS
    const newMenuItems = [...currentRootItems, ...menuItems];
    const numberOfNullPageId = newMenuItems.filter(
      (item) =>
        item.body.linkItemType === 'PAGE_ID' && item.body.fullPageId === null
    ).length;

    yield call(sitemapsApi.saveMultipleMenuItems, newMenuItems);

    yield put(
      cloneSitemapSuccess({
        id: newSitemap.id,
        ...to,
        numberOfNullPageId,
      })
    );
  } catch (error) {
    notification.error({ message: error?.response?.data?.detail });
    yield put(cloneSitemapError(error));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(GET_REGIONS_REQUEST, runGetRegions);
    yield takeLatest(SAVE_AND_CONTINUE, runSaveAndContinue);
    yield takeLatest(EDIT_SITEMAP, runEditSitemap);
    yield takeLatest(GET_SITEMAPS_REQUEST, runGetSitemaps);
    yield takeLatest(TOGGLE_SITEMAP_REQUEST, runToggleSitemap);
    yield takeLatest(DELETE_SITEMAP_REQUEST, runDeleteSitemap);
    yield takeLatest(GET_LANGUAGES, runGetAllLanguages);
    yield takeLatest(SAVE_GROUP_ITEM, runSaveGroupItem);
    yield takeLatest(SAVE_LINK_ITEM_REQUEST, runSaveLinkItem);
    yield takeLatest(ON_REORDER_LIST, runReorderList);
    yield takeLatest(GET_ALL_MENU_ITEMS, runGetAllMenuItems);
    yield takeLatest(GET_ROOT_ITEM, runGetRootMenuItem);
    yield takeLatest(GET_MENU_ITEM, runGetMenuItem);
    yield takeLatest(DELETE_MENU_ITEM, runDeleteMenuItem);
    yield takeLatest(GET_ALL_COURSES, runGetAllCourses);
    yield takeLatest(CLONE_SITEMAP, runCloneSitemap);
    yield takeLatest(GET_SITEMAP_REQUEST, runGetSitemap);
    yield takeLatest(UPDATE_ITEM_REQUEST, runUpdateGroupItem);
  };

export default function* sitemapsSagas() {
  yield all([fork(watcher())]);
}
