import { Input, Radio } from 'antd';
import React from 'react';

export default function TranslationByLanguage({
  displayName,
  translations,
  setTranslations,
  showHiddenOption,
}) {
  const handleInputChange = (event, lang) => {
    const { value, name } = event.target;
    setTranslations((prevState) =>
      prevState?.map((_lang) =>
        _lang.languageId === lang ? { ..._lang, [name]: value } : _lang
      )
    );
  };
  return (
    <div className="inm-inputs-container">
      <h4>{displayName}</h4>
      {translations?.map(({ languageId, status, value }) => (
        <div className="inm-regions-container" key={languageId}>
          <span className="inm-region-name">{languageId?.toUpperCase()}</span>
          <Input
            name="value"
            value={value}
            onChange={(e) => handleInputChange(e, languageId)}
          />
          <Radio.Group
            size="small"
            name="status"
            onChange={(e) => handleInputChange(e, languageId)}
            value={status}
          >
            <Radio value="ACTIVE">Active</Radio>
            <Radio value="DISABLED">Disabled</Radio>
            {showHiddenOption && <Radio value="INACTIVE">Hidden</Radio>}
          </Radio.Group>
        </div>
      ))}
    </div>
  );
}
