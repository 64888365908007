import { Menu, Dropdown, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  EditOutlined,
  FilterOutlined,
  MenuOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import ContainerHeader from '../../components/ContainerHeader';
import useSitemaps from '../../hooks/useSitemaps';
import Subitems from './Subitems';
import {
  getItemStyle,
  getSortedMenuItemsWithChanges,
  PARENT_TYPES,
  Reorder,
} from './utils';
import {
  deleteMenuItem,
  editSitemap,
  getMenuItemSuccess,
  onReorderList,
} from '../../store/mainActions';

import DeleteConfirmation from '../../components/DeleteConfirmation';
import useLanguages from '../../hooks/useLanguages';
import EditRegionsModal from '../../components/SubregionsModal';
import { RightClickModal } from './RightClickModal';

const { Option } = Select;

const roleOptions = [
  { value: 'ROLE_TEACHER', label: 'Teacher' },
  { value: 'ROLE_LEAD', label: 'Leads' },
  { value: 'ROLE_FREEUSER', label: 'Freeuser' },
  { value: 'ROLE_STUDENT', label: 'Student' },
  { value: 'ROLE_PARENT', label: 'Parent' },
  { value: 'ROLE_INTERNAL', label: 'Internal' },
];

export default function NewSitemapMenu() {
  const dispatch = useDispatch();
  const {
    sitemap,
    menuItems,
    getTranslation,
    langFilter,
    setFilterLang,
    roleFilter,
    setFilterRole,
  } = useSitemaps();
  const { langsBySitemapRegion, languages } = useLanguages();
  const { region, courseOrder, level } = sitemap || {};
  const { pathname } = useLocation();
  const history = useHistory();
  const [showRegionModal, setShowRegionModal] = useState(false);
  const [popupData, setPopupData] = useState({
    isVisible: false,
    isGroup: false,
    item: {},
  });

  useEffect(() => {
    if (langsBySitemapRegion && !langFilter) {
      setFilterLang(langsBySitemapRegion[0]);
    }
  }, [langsBySitemapRegion]);

  useEffect(() => {
    // clear the last menu item loaded
    dispatch(getMenuItemSuccess(undefined));
  }, []);

  if (!sitemap) {
    return <p>Loading...</p>;
  }

  const onDragEnd = (result, group) => {
    if (!result.destination) {
      return;
    }
    const indexFrom = result.source.index;
    const indexTo = result.destination.index;

    let newData;
    if (result.type === 'GROUPS') {
      newData = Reorder(group.items, indexFrom, indexTo);
    } else {
      const { items } = group.items[parseInt(result.type, 10)];
      const newItems = Reorder(items, indexFrom, indexTo);
      newData = JSON.parse(JSON.stringify(group.items));
      newData[result.type].items = newItems;
    }

    const { menuItems, changes } = getSortedMenuItemsWithChanges(newData);

    dispatch(
      onReorderList({
        newData: menuItems,
        groupId: group.id,
        sitemapId: sitemap.id,
        changes,
      })
    );
  };

  const getStyleFromFilter = (item) => {
    const { roles, translations, fullPageId, demoPageId, linkItemType } = item;

    const translation = translations?.find(
      (translation) => translation.languageId === langFilter
    );

    let classes = 'inm-sitemap-name';
    if (linkItemType === 'PAGE_ID' && !fullPageId && !demoPageId) {
      classes += ' showError';
    }
    if (!roles.includes(roleFilter)) classes += ' noSelected';
    if (translation?.status === 'DISABLED') classes += ' noClicable';
    if (translation?.status === 'INACTIVE') classes += ' hidden';

    return classes;
  };

  const handleDelete = (menuItem) => {
    dispatch(deleteMenuItem(menuItem));
  };

  const getUrlToEdit = (item) => {
    const type = item.menuItemType === 'link' ? 'newLink' : 'newGroup';
    return `${pathname}/${type}/${item.id}?parentId=${item.parentMenuItemId}&parentType=${PARENT_TYPES.group}`;
  };

  const onOk = (regions) => {
    const allLanguages = regions.reduce(
      (acc, region) => [...acc, ...languages[region]],
      []
    );

    const uniqueLanguages = [...new Set(allLanguages)];

    const editSitemapBody = {
      region: sitemap.region,
      courseOrder: sitemap.courseOrder,
      level: sitemap.level,
      regions,
      languages: uniqueLanguages,
    };

    dispatch(editSitemap({ id: sitemap.id, body: editSitemapBody }));
  };

  const handleRightClick = (item) => (e) => {
    e.preventDefault();
    setPopupData({
      isVisible: true,
      isGroup: item.menuItemType === 'group',
      item,
    });
  };

  return (
    <>
      <RightClickModal popupData={popupData} setPopupData={setPopupData} />
      <ContainerHeader
        title={`Create sitemap: ${region} - ${courseOrder} - ${level}`}
        regionTags={sitemap.regions}
      />
      <div className="inm-sitemap-filter">
        <Button
          style={{ margin: '0' }}
          type="primary"
          onClick={() => setShowRegionModal(true)}
        >
          Edit sitemap regions
        </Button>
        <EditRegionsModal
          visible={showRegionModal}
          sitemap={sitemap}
          onClose={() => setShowRegionModal(false)}
          onOk={onOk}
        />
        <Select
          suffixIcon={<FilterOutlined />}
          value={roleFilter}
          style={{ width: 100 }}
          onChange={setFilterRole}
        >
          {roleOptions?.map((role) => (
            <Option key={role.value} value={role.value}>
              {role.label}
            </Option>
          ))}
        </Select>
        <Select
          suffixIcon={<FilterOutlined />}
          value={langFilter}
          style={{ width: 100 }}
          onChange={setFilterLang}
        >
          {langsBySitemapRegion?.map((lang) => (
            <Option key={lang} value={lang}>
              {lang}
            </Option>
          ))}
        </Select>
      </div>
      <div className="inm-groups-container">
        {menuItems?.map((group) => (
          <div className="inm-group-container" key={group.id}>
            <div className="inm-group-title">
              {getTranslation(group)?.toUpperCase()}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Link
                        to={`${pathname}/newGroup?parentId=${group.id}&parentType=${PARENT_TYPES.group}`}
                      >
                        New group
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={`${pathname}/newLink?parentId=${group.id}&parentType=${PARENT_TYPES.group}`}
                      >
                        New link
                      </Link>
                    </Menu.Item>
                  </Menu>
                }
              >
                <PlusCircleOutlined className="inm-add-icon" />
              </Dropdown>
            </div>
            <DragDropContext onDragEnd={(e) => onDragEnd(e, group)}>
              <Droppable droppableId="droppable" type="GROUPS">
                {(provided) => (
                  <div className="inm-group-items" ref={provided.innerRef}>
                    {group.items?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className="inm-group-item"
                              onContextMenu={handleRightClick(item)}
                            >
                              <MenuOutlined />
                              <span className={getStyleFromFilter(item)}>
                                {getTranslation(item)}
                              </span>
                              <div className="inm-group-actions">
                                {item.menuItemType === 'group' && (
                                  <PlusCircleOutlined
                                    onClick={() =>
                                      history.push(
                                        `${pathname}/newLink?parentId=${item.id}&parentType=${PARENT_TYPES.link}`
                                      )
                                    }
                                  />
                                )}

                                <Link to={getUrlToEdit(item)}>
                                  <EditOutlined />
                                </Link>
                                <DeleteConfirmation
                                  onConfirm={() => handleDelete(item)}
                                />
                              </div>
                            </div>
                            {item.items?.length !== undefined &&
                              item.items?.length !== 0 && (
                                <Subitems
                                  handleRightClick={handleRightClick}
                                  getTranslation={getTranslation}
                                  getStyleFromFilter={getStyleFromFilter}
                                  group={item}
                                  groupIndex={index}
                                  onDelete={handleDelete}
                                />
                              )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ))}
      </div>
      <div className="inm-sitemaps-legend">
        <div>
          <div>
            <div className="showError" />
            <span>Incompleto</span>
          </div>
          <div>
            <div className="noSelected" />
            <span>No disponible para el rol seleccionado</span>
          </div>
          <div>
            <div className="hidden">aaa</div>
            <span>Oculto</span>
          </div>
          <div>
            <div className="noClicable">aaa</div>
            <span>No clicable</span>
          </div>
        </div>
      </div>
    </>
  );
}
