import apolloFetch, { apolloFetchUS } from '../../util/apolloFetch';
import { authAxiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_SITEMAPS;
export const API_ORG = process.env.REACT_APP_API_ORGANIZATIONS;

export const saveSitemap = async ({ id, payload }) => {
  const url = `${API_HOST}/sitemaps/${id}`;
  const response = await authAxiosInstance.put(url, payload);
  return response;
};

export const editSitemap = async ({ id, payload }) => {
  const url = `${API_HOST}/sitemaps/${id}`;
  const response = await authAxiosInstance.put(url, payload);
  return response;
};

export const getSitemaps = async (region) => {
  const url = `${API_HOST}/sitemaps?region=${region}`;
  const response = await authAxiosInstance.get(url);
  return response;
};

export const getSitemap = async (id) => {
  const url = `${API_HOST}/sitemaps/${id}`;
  const response = await authAxiosInstance.get(url);
  return response;
};

export const activateSitemap = async (id) => {
  const url = `${API_HOST}/sitemaps/${id}/activate`;
  const response = await authAxiosInstance.patch(url, {});
  return response;
};

export const deactivateSitemap = async (id) => {
  const url = `${API_HOST}/sitemaps/${id}/deactivate`;
  const response = await authAxiosInstance.patch(url, {});
  return response;
};

export const deleteSitemap = async (id) => {
  const url = `${API_HOST}/sitemaps/${id}`;
  const response = await authAxiosInstance.delete(url);
  return response;
};

export const saveGroupItem = async ({ id, body }) => {
  const url = `${API_HOST}/group-items/${id}`;
  const response = await authAxiosInstance.put(url, body);
  return response;
};

export const saveLinkItem = async ({ id, body }) => {
  const url = `${API_HOST}/link-items/${id}`;
  const response = await authAxiosInstance.put(url, body);
  return response;
};

export const changeOrder = async ({ id, menuItemOrder }) => {
  const url = `${API_HOST}/menu-items/${id}/order`;
  const response = await authAxiosInstance.patch(url, { menuItemOrder });
  return response;
};

export const changeMultipleOrders = async (changes) =>
  Promise.all[changes.map(changeOrder)];

export const getAllMenuItems = async ({ courseOrder, region, level }) => {
  const url = `${API_HOST}/menu-items?courseOrder=${courseOrder}&region=${region}&level=${level}`;
  const response = await authAxiosInstance.get(url);
  return response;
};

export const getMenuItem = async (id) => {
  const url = `${API_HOST}/menu-items/${id}`;
  const response = await authAxiosInstance.get(url);
  return response;
};

export const deleteMenuItem = async (id) => {
  const url = `${API_HOST}/menu-items/${id}`;
  const response = await authAxiosInstance.delete(url);
  return response;
};

export const getCourses = async () => {
  const graphQl = JSON.stringify({
    query: `query GetAllCourses {
      getAllCourses {
        id
        region
        order
        name
      }
    }`,
  });
  const response = await apolloFetch({ graphQl });
  return response.data?.getAllCourses;
};

const getCoursesUS = async () => {
  const graphQl = JSON.stringify({
    query: `query GetAllCourses {
      getAllCourses {
        id
        region
        order
        name
      }
    }`,
  });
  const response = await apolloFetchUS({ graphQl });
  return response.data?.getAllCourses;
};

export const saveMultipleMenuItems = async (menuItems) =>
  Promise.all[
    menuItems.map((menuItem) => {
      if (menuItem.body.menuItemType === 'link') {
        return saveLinkItem(menuItem);
      }
      return saveGroupItem(menuItem);
    })
  ];

export const getAllCourses = async () => {
  const courses = await getCourses();
  const coursesUS = await getCoursesUS();
  return [...courses, ...coursesUS];
};

export const getLanguages = async () => {
  const graphQl = JSON.stringify({
    query: `query AllLanguages {
      allLanguages 
    }`,
  });
  const response = await apolloFetch({ graphQl });
  return response.data?.allLanguages;
};

export const getRegions = async () => {
  const graphQl = JSON.stringify({
    query: `query Regions {
      regions 
    }`,
  });
  const response = await apolloFetch({ graphQl });
  return response.data?.regions;
};
