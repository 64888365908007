import axios from 'axios';
// import { getCurrentUser } from "../utils";
// import { refreshUser } from "./apiMiddleware";

const APOLLO_SERVER = process.env.REACT_APP_APOLLO_SERVER;
const APOLLO_SERVER_US = process.env.REACT_APP_APOLLO_SERVER_US;

const apolloFetch = async ({ graphQl }) => {
  const response = await axios({
    url: `${APOLLO_SERVER}`,
    method: 'POST',
    data: JSON.parse(graphQl),
  });
  return response.data;
};

export const apolloFetchUS = async ({ graphQl }) => {
  const response = await axios({
    url: `${APOLLO_SERVER_US}`,
    method: 'POST',
    data: JSON.parse(graphQl),
  });
  return response.data;
};

export default apolloFetch;
